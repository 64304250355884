import { API_URL, IS_API_GO, API_URL_GO } from "constants/config";
import axios from "axios";
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
  domain: window["_env_"]?.REACT_APP_AUTH0_DOMAIN ?? process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: window["_env_"]?.REACT_APP_AUTH0_CLIENT_ID ?? process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window["_env_"]?.REACT_APP_AUTH0_CALLBACK_URL ?? process.env.REACT_APP_AUTH0_CALLBACK_URL
  }
});
const isAPIGO = IS_API_GO

const http = axios.create({
  baseURL: API_URL,
});

const httpGO = axios.create({
  baseURL: API_URL_GO
});


httpGO.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      //@ts-ignore
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

httpGO.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      auth0.loginWithRedirect();
    } else if (error.response.status === 403) {
      window.location.href = "/access-deniend";
    } else if (error.response.status >= 500) {
      window.location.href = "/something-went-wrong";
    } else return error;
  }
);

http.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      //@ts-ignore
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      auth0.loginWithRedirect();
    } else if (error.response.status === 403) {
      window.location.href = "/access-deniend";
    } else if (error.response.status >= 500) {
      window.location.href = "/something-went-wrong";
    } else return error;
  }
);

const getGO = (url: string, headers = {}, params = {}) => {
  return httpGO.get(url,  {
    ...params,
    headers: {
      ...headers,
    }
  });
};

const putGO = (url: string, data: any, headers = {}) => {
  return httpGO.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const postGO = (url: string, data: any, headers = {}, params = {}) => {
  return httpGO.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};

const removeGO = (url: string, data: any, headers = {}) => {
  return httpGO.delete(url, {
     headers: {
       ...headers,
     },
     data,
   });
 };

const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  isAPIGO,
  getGO,
  postGO,
  putGO,
  http,
  httpGO,
  get,
  post,
  put,
  remove,
  removeGO,
  patch,
};

export default module;
